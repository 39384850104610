import { useEffect } from "react";
import { useState } from "react";
import { fetchData } from "../../lib/service";
import { getStats } from "../../lib/service/stats";
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from "react-countup";

const formattedStats = {
    servers: "Servers",
    users: "Users",
    dailyInteractions: "Daily interactions"
}

export function StatsSection({ addAlert }) {
    const [stats, setStats] = useState();
    const [hasBeenVisible, setHasBeenVisible] = useState(false);

    useEffect(() => {
        fetchData(addAlert, setStats, getStats, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<div className="relative mt-12 overflow-x-clip">
        <div
            className="absolute rotate-[2deg] origin-right h-full inset-y-0 w-[200%] -right-1/2 bg-gradient-to-b from-theme2-600 to-theme2-500 border-t border-theme2-400/50"
            aria-hidden="true"
        />
        <div className="py-24 sm:py-32 relative">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:max-w-none">
                    <div className="text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                            Trusted by worldwide servers
                        </h2>
                    </div>
                    <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center md:grid-cols-3">
                        {Object.entries(formattedStats).map((stat, i) => (
                            <div key={i} className="flex flex-col bg-gray-400/5 p-8">
                                <dt className="text-sm font-semibold leading-6 text-gray-600">{stat[1]}</dt>
                                <dd className="order-first text-3xl font-semibold tracking-tight text-white">{stats?.[stat[0]] !== undefined ?
                                    <VisibilitySensor onChange={setHasBeenVisible} active={!hasBeenVisible} partialVisibility>
                                        {({ isVisible }) =>
                                            isVisible || hasBeenVisible ? <CountUp start={0} duration={3} end={stats[stat[0]]} suffix="+" separator="," /> : <span>0+</span>
                                        }
                                    </VisibilitySensor>
                                    : <span>...</span>}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    </div>);
}