import { ChatBubbleBottomCenterTextIcon, LanguageIcon, PhotoIcon, LifebuoyIcon, SpeakerWaveIcon, SwatchIcon } from "@heroicons/react/24/outline";

const features = [
    {
        name: 'Chat & discussions',
        description:
            'Management of different discussions at the same time, possibility to select a character, a language and to keep old messages in context.',
        icon: ChatBubbleBottomCenterTextIcon,
    },
    {
        name: 'Voice chat',
        description:
            'Chat by voice with the bot, you can ask him questions and he will answer you by voice ! Turn it into a voice assistant and ask it questions when you need to.',
        icon: SpeakerWaveIcon
    },
    {
        name: 'Image generation',
        description:
            'Generate images with the bot. You can also edit images, replace elements, remove background, and much more.',
        icon: PhotoIcon
    },
    {
        name: "Customization",
        description: "Customize the bot to your liking, change the size of images, how to open discussions, and much more.",
        icon: SwatchIcon
    },
    {
        name: "International",
        description: "The bot can be used in any language supported by ChatGPT. In addition to that, all the interface and commands can be translated into: French, English or Spanish",
        icon: LanguageIcon
    },
    {
        name: "Support",
        description: "You can create custom characters for your server and use them to turn the bot into an assistant for your members.",
        icon: LifebuoyIcon
    }
]

export default function FeaturesSection() {
    return (<div className="py-24 sm:py-32 bg-gradient-to-b from-theme2-500 to-[200%] to-theme-900">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
                <h2 className="text-base font-semibold leading-7 text-theme-600">Boost your community</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                    Everything you need in AI for your server
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-400">
                    We do our best to offer you amazing and unique features that will help you to boost your community and make it more interactive.
                </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                    {features.map((feature) => (
                        <div key={feature.name} className="relative pl-16">
                            <dt className="text-base font-semibold leading-7 text-white">
                                <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-theme-600">
                                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                </div>
                                {feature.name}
                            </dt>
                            <dd className="mt-2 text-base leading-7 text-gray-400">{feature.description}</dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    </div>);
}