import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../../images/logo.png";

import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Link } from "react-router-dom";

const navigation = [
    { name: 'Shop', href: '/shop' },
    { name: 'Support', href: '/support' },
    { name: "Vote", href: "/vote" }
]

export default function Header(props) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <header className="absolute top-0 inset-x-0 z-50">
            <nav className="mx-auto flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                <Link to="/" className="-m-1.5 p-1.5 flex items-center gap-3">
                    <img className="h-10 w-auto rounded-full" src={logo} alt="" />
                    <h1 className="text-white font-semibold text-xl tracking-tight">SmartCord</h1>
                </Link>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-8 w-8" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden lg:items-center lg:flex lg:gap-x-12">
                    {navigation.map((item) => (
                        <a key={item.name} target="_blank" rel="noreferrer" href={item.href} className="transition-colors hover:text-theme-100 text-sm font-semibold leading-6 text-white">
                            {item.name}
                        </a>
                    ))}
                    <a href="/invite" target="_blank" rel="noreferrer" className="transition-colors text-sm font-semibold leading-6 text-white bg-theme-500 hover:bg-theme-600 rounded-full px-5 py-1.5">
                        Add the bot
                    </a>
                </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-theme2-500 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <Link to="/" className="-m-1.5 p-1.5 flex items-center gap-3">
                            <img
                                className="h-10 w-auto"
                                src={logo}
                                alt=""
                            />
                            <h1 className="text-white font-semibold text-xl tracking-tight">SmartCord</h1>
                        </Link>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-white"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-200/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="transition-colors -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-200 hover:bg-theme-800"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            <div className="py-6">
                                <a href="/invite" target="_blank" rel="noreferrer"
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-200 hover:bg-theme-800"
                                >
                                    Add the bot
                                </a>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
}