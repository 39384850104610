import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import FeaturesSection from "./Features";
import { HeroSection } from "./Hero";
import { StatsSection } from "./Stats";

export default function Home(props) {
    return (<>
        <Header {...props} />

        <HeroSection />
        <StatsSection {...props} />
        <FeaturesSection />

        <Footer {...props} />
    </>)
}